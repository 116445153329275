import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["errors", "element", "form"]
  static values = {
    method: { type: String, default: "card" }
  }

  connect() {
    const stripe = Stripe(this.data.get("public-key"))
    const style = JSON.parse(this.data.get("style"))

    const element = this.createElement(stripe, this.methodValue, { style: style });
    this.confirmSetupMethod(stripe, element, this.methodValue);
  }

  /**
  * Create the stripe element to use
  */
  createElement(stripe, method, options) {
    const elements = stripe.elements();
    let elementMethod = null;

    switch (method) {
      case "iban":
        elementMethod = elements.create("iban", options); //this.getOptionsForIban()
        break;
      case "card":
        elementMethod = elements.create("card", options);
        break;
    }

    elementMethod.mount(this.elementTarget);
    this.initDisplayError(elementMethod, this.errorsTarget);

    return elementMethod;
  }

  /**
   * Init display stripe errors
   */
  initDisplayError(element, errorsContainer) {
    element.addEventListener("change", (event) => {
      if (event.error) {
        errorsContainer.textContent = event.error.message;
      } else {
        errorsContainer.textContent = "";
      }
    });
  }

  /**
   * Ask stripe to confirm the setup method
   */
  confirmSetupMethod(stripe, element, method) {
    const controller = this;

    this.formTarget.addEventListener("submit", function (event) {
      event.preventDefault();

      switch (method) {
        case "iban":
          controller.confirmIbanSetup(stripe, element, controller);
          break;
        case "card":
          controller.confirmCardSetup(stripe, element, controller);
          break;
      }
    });
  }

  /**
   * Ask stripe to confirm the card setup
   */
  confirmCardSetup(stripe, card, controller) {
    const form = controller.formTarget;
    let name = form.querySelector("#name_or_entreprise").value;

    stripe
      .createPaymentMethod({
        card: card,
        type: "card",
        billing_details: {
          name: name,
          email: form.dataset.email,
        },
      })
      .then((result) => {
        if (result.error) {
          controller.errorsTarget.textContent = result.error.message;
        } else {
          controller.addHiddenField(form, "payment_method_id", result.paymentMethod.id);
          form.submit();
        }
      });
  }

  /**
   * Create hidden field for form
   */
  addHiddenField(form, name, value) {
    let input = document.createElement("input");
    input.setAttribute("type", "hidden");
    input.setAttribute("name", name);
    input.setAttribute("value", value);
    form.appendChild(input);
  }
}

